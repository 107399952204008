<template>
  <div class="not-party-people">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :search-param="searchParam"
      :extra-params="extraParams"
      :table-url="tableUrl"
      :is-operate-column="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="notReturn"></v-button>
      </template>
      <template #searchSlot>
        <area-select @changeModel="changeModel" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { createAlinkVNode, createHTMLVNode } from "@/utils/utils";
import { getNotPartyPeopleList } from "@/views/rubbishSort/activity/api";

export default {
  name: "notPartyPeople",
  data() {
    return {
      searchParam: {
        spaceIds: null,
      },
      extraParams: {
        topicId: this.$route.query.id,
      },
      headers: [
        {
          prop: "villageName",
          label: "所属小区",
        },
        {
          prop: "communityName",
          label: "所属社区",
        },
        {
          prop: "building",
          label: "所属楼栋",
        },
        {
          prop: "houseName",
          label: "所属房号",
        },
        {
          prop: "userList",
          label: "房号下住户信息",
          formatter: (row) => {
            let oStr = "";
            if (row.userList) {
              row.userList.forEach((ele) => {
                oStr += `<p>${ele.userName}-${ele.mobileNum}</p>`;
              });
            }

            return createHTMLVNode(this, oStr, row, "", {
              cb: (e, oBtn, row, prop) => {},
            });
          },
        },
      ],
      tableUrl: getNotPartyPeopleList,
    };
  },
  methods: {
    changeModel(value) {
      this.searchParam.spaceIds = value;
    },
    notReturn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.not-party-people {
  box-sizing: border-box;
  height: 100%;
  .realNameAuditForm-addAddress {
    /deep/ .el-dialog__body {
      max-height: 600px;
      overflow: auto;
      .img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
